<template>
    <div
        style="width: 100%; height: 100%; background-color: #2A6DFF; display: flex; flex-direction: column; justify-content: center; padding-top: 15px;">
        <el-affix :offset="1">
            <div style="display: flex;  width: 100%; background-color: #3270E0; justify-content: center; ">
                <div style="display: flex; justify-content: space-between; width: 1300px; ">
                    <img src="../assets/logo.svg" />
                    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :ellipsis="false"
                        @select="handleSelect">
                        <!-- <el-menu-item index="0">
                    <img style="width: 100px" src="../assets/logo.svg" alt="Element logo" />
                </el-menu-item> -->
                        <el-menu-item index="1"
                            style="color:white; font-size: 18px; font-weight: bold; ">首页</el-menu-item>
                        <el-menu-item index="2"
                            style="color:white; font-size: 18px; font-weight: bold; ">产品介绍</el-menu-item>
                        <el-menu-item index="3"
                            style="color:white; font-size: 18px; font-weight: bold; ">关于我们</el-menu-item>
                        <el-menu-item index="4"
                            style="color:white; font-size: 18px; font-weight: bold; ">优惠购买</el-menu-item>
                        <el-menu-item index="5"
                            style="color:white; font-size: 18px; font-weight: bold; ">联系我们</el-menu-item>
                    </el-menu>
                </div>
            </div>
        </el-affix>
        <div
            style="display: flex; width: 100%; justify-content: center; color: white; font-size: 60px; font-weight: bold; ">
            <div style="width: 950px;">
                <div style="display: flex; flex-direction: column; margin-top: 100px;">
                    <view style="width: 800px; text-align: left; ">您的网站想开通预约服务？</view>
                    <view style="width: 600px; text-align: left;">那就用秒约吧！</view>
                    <view style=" font-size: 15px; text-align: left; margin-top: 30px; ">急速开通！试用期只需要$39元/月</view>
                    <el-button round
                        style="width: 120px; height: 40px; margin-top: 30px; size: large; font-size: 15px; color: #286ADE; font-weight: bold; ">马上预约</el-button>
                </div>
                <div style="position: relative; left: -230px; ">
                    <img style="width: 1200px; left: -100px;" src="../assets/hero-img.png">
                </div>
            </div>
            <!-- 登录窗口 -->
            <div style="margin-top: 150px;">
                <!-- 第一步-->
                <el-form :model="company" size="large" label-width="80px" v-if="step === 1"
                    style="width: 300px; padding:15px; color: #FFFFFF;" label-position="top">
                    <!-- <el-form-item>
                        <div style="border-bottom: 1px dashed #F7941A; width: 100%; text-align: left ">
                            <div style="color: #FFFFFF; font-size: 20px; font-weight: bold;">{{ $t('login.loginTip') }}
                            </div>
                            <div
                                style="color: #FFFFFF; font-size: 25px; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-weight: bold;">
                                Booking1s</div>
                        </div>
                    </el-form-item> -->
                    <el-form-item class="custom-label-color" :label="$t('login.userName')">
                        <el-input v-model="company.companyEmail" />
                    </el-form-item>
                    <el-form-item class="custom-label-color" :label="$t('login.password')">
                        <el-input v-model="company.companyPwd" type="password" show-password />
                    </el-form-item>
                    <el-form-item class="custom-label-color" :label="$t('login.pwdConfim')" v-if="isLogin === false">
                        <el-input v-model="company.conPwd" type="password" show-password />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary"
                            style="width: 100%; height: 40px; background-color: #F7941A; border: none;"
                            @click="onNextToTwo" :loading="isLoading" v-if="isLogin === false">Next</el-button>
                        <el-button type="primary" v-if="isLogin === true" :loading="isLoading"
                            style="width: 100%; height: 40px; background-color: #F7941A; border: none;"
                            @click="onSubmit">{{
                                $t('login.login') }}</el-button>

                    </el-form-item>
                    <el-form-item>
                        <div style="text-align: right; width: 100%; display: flex; justify-content: end; ">
                            <el-button @click="registerHandler" v-if="isLogin === true">{{ $t('login.register')
                                }}</el-button>
                            <el-button @click="toLoginHandler" v-if="isLogin === false">{{ $t('login.alLogin')
                                }}</el-button>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <div style="color: #FFFFFF; vertical-align: text-bottom; display: flex; ">
                            <el-link style="color:white;" @click="openPage('privacy.html')">{{ $t('company.yinsi') }}</el-link>
                            <div style="width: 20px;"></div>
                            <el-link style="color:white;" @click="openPage('service.html')">{{ $t('company.service') }}</el-link>
                        </div>
                    </el-form-item>
                </el-form>
                <!--第二步-->
                <el-form v-if="step === 2" style="width: 300px; padding:15px; "
                    label-position="top">
                    <el-form-item>
                        <div style="border-bottom: 1px dashed #F7941A; width: 100%; text-align: left ">
                            <div style="color: #FFFFFF; font-size: 25px; font-weight: bold;">输入验证码</div>
                            <div
                                style="color: #000000; font-size: 16px; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; font-weight: bold;">
                                请检查您的邮箱</div>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <div>
                            我们发送了一封包括验证码的邮箱到以下邮箱
                        </div>
                        <div style="color: #F7941A; width: 100%; text-align: center ">
                            {{ company.companyEmail }}
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <div style="display: flex; justify-content: space-around; width: 100%; ">
                            <el-input id="fir" class="div_class" maxlength="1" v-model="f" @input="change1Handler" />
                            <el-input id="sec" class="div_class" maxlength="1" v-model="s" @input="change2Handler" />
                            <el-input id="thr" class="div_class" maxlength="1" v-model="t" @input="change3Handler" />
                            <el-input id="for" class="div_class" maxlength="1" v-model="fs" @input="change4Handler" />
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <div>
                            Enter the code in the email to creatingyour account. The code will expire in 15min.
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <div style="text-align: right; width: 100%; padding-top: 60px">
                            <el-button type="primary"
                                style="width: 100%; height: 40px; background-color: #F7941A; border: none;"
                                @click="onNextToThree">Next</el-button>
                        </div>
                    </el-form-item>
                </el-form>
                <el-form v-if="step === 3" style="width: 300px; padding:15px;"
                    label-position="top">
                    <el-form-item>
                        <div style=" width: 100%; text-align: center; padding-top: 50px; ">
                            <div style="color: #F7941A; font-size: 25px; font-weight: bold;">恭喜您</div>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <div style="color: #F7941A; width: 100%; text-align: center ">
                            <img style="width: 100px; height: 100px; " src="../assets/complete.png" />
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <div style="text-align: right; width: 100%; padding-top: 100px">
                            <el-button type="primary"
                                style="width: 100%; height: 40px; background-color: #F7941A; border: none;"
                                @click="beginHandler">马上开始</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div style="width: 100%; background-color: #FEFEFE; display: flex; justify-content: center; ">
            <div style="display: flex; margin-top: 20px; ">
                <el-row :gutter="20" style="width: 1200px;">
                    <el-col :span="12">
                        <div style="display: flex; flex-direction: column; justify-content: flex-start; ">
                            <view style="font-size: 30px; font-weight: bold; text-align: left; margin-top: 50px; ">
                                秒约的超酷功能！
                            </view>
                            <view style="width: 500px; margin-top: 40px; text-align: left; ">
                                帮助您在极短的时间内，为网站增加一个非常实用的预约功能。这会让您的客户们非常方便地预约您的服务，提升您的业务效率！
                                同时，我们也会为您保留一个非常方便的后台，让您随时监控和管理您的预约数据。
                            </view>
                            <el-button round
                                style="width: 110px; border: 1px solid #2A6DFF; color: #2A6DFF; height: 40px; margin-top: 20px; ">马上开通</el-button>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <div
                                    style="background-color: white; width: 200px; height: 200px; display: flex; flex-direction: column; text-align: left; box-shadow: #000000;">
                                    <div class="icon">
                                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g id="rocket 1">
                                                <g id="Group">
                                                    <path id="Vector"
                                                        d="M62.7999 18.5L54.2999 9.99997L54 9.79997C53.5 9.29997 52.7 9.29997 51.9 9.29997L38.9 10.4C28.5 1.79997 15.4999 -1.90003 4.79995 0.999972C2.99995 1.29997 1.69995 2.79997 1.09995 4.69997C-1.80005 15.3 1.89995 28.4 10.3999 38.5L9.09995 51.8C9.09995 52.8 9.49995 53.8 10.1999 54.5L18.4 62.8C18.9 63.3 19.9999 63.9 20.7999 63.9C21.0999 63.9 21.3 63.9 21.9 63.6C22.9 62.9 23.9999 62.3 24.2999 60.7L25.6 50.1C26.9 50.6 28.3 51.3 29.6 51.7C30.3 51.9 30.7 52 31.2 52C32.5 52 33.9 51.5 34.9 50.4L50.2999 35C51.5999 33.7 52.1999 31.3 51.5999 29.4C51.0999 28.1 50.5 26.7 50 25.4L60.9 24.1C62.2 23.8 62.9999 23 63.5999 21.7C64.0999 20.7 63.8999 19.6 62.7999 18.5ZM19.7 56.3L14.5999 51.2L15.3999 43.5C17.2999 45.1 19.1 46.4 21 47.8L19.7 56.3ZM31.4 47C26.0999 45.1 20.8 41.7 16.5 37.4C7.49995 28.4 3.19995 15.9 5.89995 5.99997C16 3.39997 28.1999 7.39997 37.2999 16.7C41.5999 21 44.7 26 46.9 31.6L31.4 47ZM47.5999 20.7C46.2999 18.8 44.8999 17 43.2999 15.1L51 14.6L56.0999 19.7L47.5999 20.7Z"
                                                        fill="#286ADE" />
                                                    <path id="Vector_2"
                                                        d="M56.4 40.1C55.6 39.6 54.3 39.8 53.5 40.4C52.4 41.2 47.4 46.2 46.6 47C39.7 53.9 39.7 53.9 40.2 55.5C40.3 56 40.5 56.3 41 56.8C44.5 60.3 49.5 61.1 53.5 61.1C56.4 61.1 58.6 60.6 58.8 60.6C59.9 60.3 60.7 59.5 60.9 58.5C60.9 58 63.3 46.5 57.2 40.7C56.7 40.3 56.7 40.3 56.4 40.1ZM55.9 55.8C53.2 56.1 49.5 56.1 46.6 54.7C48.7 52.3 52.4 48.9 54.6 46.7C55.9 49.4 55.9 53.1 55.9 55.8Z"
                                                        fill="#286ADE" />
                                                    <path id="Vector_3"
                                                        d="M25.7999 16.7C20.6999 16.7 16.7999 20.7 16.7999 25.7C16.7999 30.7 20.7999 34.7 25.7999 34.7C30.7999 34.7 34.7999 30.7 34.7999 25.7C34.7999 20.7 30.8999 16.7 25.7999 16.7ZM25.7999 29.4C23.6999 29.4 22.0999 27.8 22.0999 25.7C22.0999 23.6 23.6999 22 25.7999 22C27.8999 22 29.4999 23.6 29.4999 25.7C29.5999 27.8 27.9999 29.4 25.7999 29.4Z"
                                                        fill="#286ADE" />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <view style="font-size: 25px; font-weight: bold;">云端存储</view>
                                    <view>预约数据将通过加密存储，也可以随时导出导入！</view>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div
                                    style="background-color: white; width: 200px; height: 200px; display: flex; flex-direction: column; text-align: left; box-shadow: #000000;">
                                    <div class="icon">
                                        <svg width="64" height="64" viewBox="0 0 64 64" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M39 26.4L30.1 35L27.9 32.8C26.8 31.7 25.1 31.7 24 32.8C22.9 33.9 22.9 35.6 24 36.7L27.9 40.6C28.5 41.2 29.3 41.4 30.1 41.4C30.9 41.4 31.8 41.1 32.6 40.6L42.9 30.6C44 29.5 44 27.8 42.9 26.7C41.7 25.6 40.1 25.3 39 26.4Z"
                                                fill="#286ADE" />
                                            <path
                                                d="M58.7 23.1C55.9 20 52 17.8 47.9 17C45.7 13.4 42.1 10.6 38.2 9.20001C36.3 8.40001 34.3 8.10001 32.1 8.10001C22.1 8.10001 13.7 15.9 13.2 25.6C5.6 26.7 0 33.1 0 40.6C0 49 7 55.9 15.3 55.9H44.2C55.1 55.9 64 47 64 36.2C64 31.4 62.1 26.7 58.7 23.1ZM44 50.6H15C9.4 50.6 5.3 46.4 5.3 40.9C5.3 35.4 9.8 31.2 15 31.2H18.3V27.3C18.3 19.8 24.4 13.7 31.9 13.7C33.6 13.7 35 14 36.4 14.5C39.5 15.6 42.2 17.8 43.9 20.9L44.7 22.3L46.1 22.6C49.4 22.9 52.8 24.5 55 27.1C57.5 29.9 58.9 33.2 58.9 36.8C58.4 44.2 52 50.6 44 50.6Z"
                                                fill="#286ADE" />
                                        </svg>
                                    </div>
                                    <view style="font-size: 25px; font-weight: bold;">定制界面</view>
                                    <view>根据您的企业主色和LOGO定制预约界面</view>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <div
                                    style="background-color: white; width: 200px; height: 200px; display: flex; flex-direction: column; text-align: left; box-shadow: #000000;">
                                    <div class="icon">
                                        <svg fill="none" height="58" viewBox="0 0 64 58" width="64"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="m62.1 3.00001c-2.7-2.700002-7-2.700003-9.6-.3l-34.8 32.39999c-1.1 0-2.4.3-3.7.8-2.4 1.1-3.5 2.9-4.5 4.5-1.1 1.9-2.1 3.7-5.6 5.9l-3.9 2.5 3.7 2.1c2.1 1.3 10.2 6.2 17.1 6.2 2.1 0 4-.5 5.6-1.6 2.4-1.9 3.7-4.3 3.5-7v-.3l32.1-35.3c2.8-2.99999 2.5-7.19999.1-9.89999zm-38.8 48.19999c-2.1 1.6-8.3-.3-13.4-2.7 1.9-1.9 2.9-3.7 3.7-5.1.8-1.3 1.3-2.1 1.9-2.4 1.9-.8 3.7 0 4 0 2.4 1.9 5.1 4.8 5.1 7.5.3.5 0 1.6-1.3 2.7zm34.8-42.09999-30 33.19999c-1.3-2.1-3.2-4-4.8-5.4l32.6-30.19999c.5-.5 1.6-.5 2.1 0 .9.6.9 1.6.1 2.4z"
                                                fill="#286ade" />
                                        </svg>
                                    </div>
                                    <view style="font-size: 25px; font-weight: bold;">开通超快</view>
                                    <view>只需要复制几行代码放在网页上即可开通预约</view>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div
                                    style="background-color: white; width: 200px; height: 200px; display: flex; flex-direction: column; text-align: left; box-shadow: #000000;">
                                    <div class="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="64" viewBox="0 0 118 94"
                                            role="img">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M24.509 0c-6.733 0-11.715 5.893-11.492 12.284.214 6.14-.064 14.092-2.066 20.577C8.943 39.365 5.547 43.485 0 44.014v5.972c5.547.529 8.943 4.649 10.951 11.153 2.002 6.485 2.28 14.437 2.066 20.577C12.794 88.106 17.776 94 24.51 94H93.5c6.733 0 11.714-5.893 11.491-12.284-.214-6.14.064-14.092 2.066-20.577 2.009-6.504 5.396-10.624 10.943-11.153v-5.972c-5.547-.529-8.934-4.649-10.943-11.153-2.002-6.484-2.28-14.437-2.066-20.577C105.214 5.894 100.233 0 93.5 0H24.508zM80 57.863C80 66.663 73.436 72 62.543 72H44a2 2 0 01-2-2V24a2 2 0 012-2h18.437c9.083 0 15.044 4.92 15.044 12.474 0 5.302-4.01 10.049-9.119 10.88v.277C75.317 46.394 80 51.21 80 57.863zM60.521 28.34H49.948v14.934h8.905c6.884 0 10.68-2.772 10.68-7.727 0-4.643-3.264-7.207-9.012-7.207zM49.948 49.2v16.458H60.91c7.167 0 10.964-2.876 10.964-8.281 0-5.406-3.903-8.178-11.425-8.178H49.948z"
                                                fill="#286ade"></path>
                                        </svg>
                                    </div>
                                    <view style="font-size: 25px; font-weight: bold;">支持多网点</view>
                                    <view>无论是一个还是多个，不论在一地还是在多地</view>
                                </div>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!--关于我们-->
        <div style="background-color: white; padding-top:100px; ">
            <el-row>
                <el-col :span="12">
                    <img src="../assets/about-img.png" />
                </el-col>
                <el-col :span="12">
                    <div style="display: flex; flex-direction: column; text-align: left; padding-top: 30px; ">
                        <view style="font-size: 30px; font-weight: bold; ">关于我们</view>
                        <view style="width:500px; padding-top: 30px; padding-bottom: 30px;">
                            我们是一个富有年青活力的团队，成员来自澳大利来、中国，拥有丰富的网络应用服务经验和运营能力，努力为我们的客户提供稳定长久的服务。</view>
                        <view
                            style="background-color: #2A6DFF; width: 500px; height: 90px; color: white; padding-top: 10px; padding-bottom: 10px; display: flex; border-radius: 10px; ">
                            <div
                                style="display: flex; flex-direction: column; width: 280px; padding-top:15px; text-align: center; border-right: 2px solid #FFFFFF;">
                                <view style="font-size: 30px; font-weight: bold; ">7291</view>
                                <view>预约次数</view>
                            </div>
                            <div
                                style="display: flex; flex-direction: column; width: 150px; padding-top:15px; text-align: center; border-right: 2px solid #FFFFFF;">
                                <view style="font-size: 30px; font-weight: bold; ">1372</view>
                                <view>服务终端用户</view>
                            </div>
                        </view>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!--客户评价-->
        <div style="background-color: #2A6DFF; height: 400px; padding-top: 50px; font-weight: bold; ">
            <div style="font-size: 40px; font-weight: bold; color: #FFFFFF; ">客户对我们的评价</div>
            <div style="width:100%; display: flex; justify-content: center; margin-top: 60px; ">
                <el-row style="width:1200px;">
                    <el-col :span="12">
                        <div style="display: flex;">
                            <img style="width: 100px; height: 100px" src="../assets/2.png" />
                            <div style="display: flex; flex-direction: column; color:white; padding-left: 50px; ">
                                <view style="width:400px; text-align: left;">Lorem ipsum dolor sit amet, conseta
                                    dipscing elitr, sed diam nonumy eirmod temp
                                    invidunt ut laboreet dolore magna aliquyamera. Lorem ipsum dolor sitamet.

                                </view>
                                <view style="width:400px; text-align: left; font-weight: bold; line-height: 50px; ">
                                    Jonathon Smith
                                </view>
                                <view style="width:400px; text-align: left;">
                                    Founder Food fanda
                                </view>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div style="display: flex;">
                            <img style="width: 100px; height: 100px" src="../assets/3.png" />
                            <div style="display: flex; flex-direction: column; color:white;  padding-left: 50px;">
                                <view style="width:400px; text-align: left;">Lorem ipsum dolor sit amet, conseta
                                    dipscing elitr, sed diam nonumy eirmod temp
                                    invidunt ut laboreet dolore magna aliquyamera. Lorem ipsum dolor sitamet.

                                </view>
                                <view style="width:400px; text-align: left; font-weight: bold; line-height: 50px;">
                                    Dev Ed
                                </view>
                                <view style="width:400px; text-align: left;">
                                    Teacher at Udemy
                                </view>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!-- 优惠购买 -->
        <div
            style="background-color: #FBFBFB; display: flex; justify-content: center; padding-bottom: 100px; padding-top: 100px; ">
            <div style=" display: flex; justify-content: center; flex-direction: column; width:1200px; ">
                <div style="width:1200px; display: flex; flex-direction: column; ">
                    <view style="font-size: 50px; font-weight: bold; text-align: left; ">优惠购买</view>
                    <view style="text-align: left; line-height: 50px;; ">“秒约”服务目前提供三种付费模式：</view>
                </div>
                <div>
                    <el-row>
                        <el-col :span="8">
                            <div
                                style="width:350px; height: 500px; background-color: white; display: flex; flex-direction: column; align-items: center; justify-content: center; ">
                                <img src="../assets/pricing-1.svg" style="width:60px;" />
                                <view style="font-size: 25px; line-height: 90px; font-weight: bold; ">体验版</view>
                                <view style="font-size:40px; color: #2A6DFF; font-weight: bold; ">$39.00</view>
                                <view style="line-height: 40px;">基础版</view>
                                <view style="line-height: 40px;">支持 1 个网点</view>
                                <view style="line-height: 40px;">员工帐号 1 个</view>
                                <view style="line-height: 40px;">使用时间 1 个月</view>
                                <el-button round
                                    style="font-size: 20px; width: 130px; height: 50px; color: #2A6DFF ;">马上开通</el-button>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div
                                style="width:350px; height: 500px; background-color: white; display: flex; flex-direction: column; align-items: center; justify-content: center; ">
                                <img src="../assets/pricing-1.svg" />
                                <view style="font-size: 25px; line-height: 90px; font-weight: bold; ">标准版</view>
                                <view style="font-size:40px; color: #2A6DFF; font-weight: bold; ">$199.00</view>
                                <view style="line-height: 40px;">预约系统标准版</view>
                                <view style="line-height: 40px;">支持 1-3 个网点</view>
                                <view style="line-height: 40px;">员工帐号 3-6 个</view>
                                <view style="line-height: 40px;">员工帐号 3-6 个</view>
                                <el-button round
                                    style="font-size: 20px; width: 130px; height: 50px; color: #2A6DFF ;">马上开通</el-button>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div
                                style="width:350px; height: 500px; background-color: white; display: flex; flex-direction: column; align-items: center; justify-content: center; ">
                                <img src="../assets/pricing-1.svg" />
                                <view style="font-size: 25px; line-height: 90px; font-weight: bold; ">高级版</view>
                                <view style="font-size:40px; color: #2A6DFF; font-weight: bold; ">$399.00</view>
                                <view style="line-height: 40px;">预约系统高级版</view>
                                <view style="line-height: 40px;">支持网点不限</view>
                                <view style="line-height: 40px;">员工帐号不限</view>
                                <view style="line-height: 40px;">使用期限 1 年</view>
                                <el-button round
                                    style="font-size: 20px; width: 130px; height: 50px; color: #2A6DFF ;">马上开通</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
        <!-- 联系我们 -->
        <div style="background-color: #FBFBFB;display: flex; justify-content: center; ">
            <div style="display: flex; flex-direction: column; width: 1200px; ">
                <view style="font-size: 45px; font-weight: bold; ">欢迎您联系我们</view>
                <view style="line-height: 60px;">
                    如果您有任何问题或建议，请随时联系我们，您的反馈对我们非常重要。 我的邮件地址是：booking1s.com@gmail.com
                </view>
                <el-row>
                    <el-col :span="12">
                        <el-form :model="form" label-width="auto" style="max-width: 600px">
                            <el-form-item label="">
                                <el-col :span="10">
                                    <el-input style="height: 40px; border-radius: 30px; " placeholder="Your Name" />
                                </el-col>
                                <el-col :span="4"></el-col>
                                <el-col :span="10">
                                    <el-input style="height: 40px;" placeholder="Your E-mail" />
                                </el-col>
                            </el-form-item>
                            <el-form-item label="">
                                <el-col :span="10">
                                    <el-input style="height: 40px;" placeholder="Subject" />
                                </el-col>
                                <el-col :span="4"></el-col>
                                <el-col :span="10">
                                    <el-input style="height: 40px;" placeholder="Number" />
                                </el-col>
                            </el-form-item>
                            <el-form-item label="">
                                <el-input type="textarea" placeholder="Message" />
                            </el-form-item>
                            <el-form-item>
                                <el-button round style="width: 120px; height: 45px;" type="primary" @click="onSubmit">提交信息</el-button>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="12">
                        <img src="../assets/contact-img.png" />
                    </el-col>
                </el-row>
            </div>
        </div>
        <!-- 订阅我们的最新消息 -->
        <div style="height: 600px; background-color: #2869DD;">
            <div style="display: flex; flex-direction: column; margin-top: 40px;">
                <view style="font-size: 40px; font-weight: bold; color: white; ">订阅我们的最新消息</view>
                <view style="color: white; line-height: 60px; font-size: 18px; font-weight: bold;">
                    如果您想了解更多信息，请订阅我们的电子邮件列表</view>
                <div style="display: flex; justify-content: center; ">
                    <el-input style="width: 290px; height: 50px;" placeholder="your email" ></el-input>
                    <view style="width:20px;"></view>
                    <el-button style="height: 50px;">现在订阅</el-button>
                </div>
            </div>
            <div style="display: flex; justify-content: center; ">
                <div style="display: flex; width: 1300px;  padding-top: 100px;justify-content: space-between; ">
                    <div>
                        <img src="../assets/logo.svg" />
                    </div>
                    <div style="display: flex; flex-direction: column; text-align: left; color: white;">
                        <view style="font-size: 19px; color: white; padding-bottom: 30px;">联系方式</view>
                        <view>booking1s.com@gmail.com</view>
                    </div>
                    <div style="display: flex; flex-direction: column; text-align: left; color: white;">
                        <view style="font-size: 19px; color: white; padding-bottom: 30px;">条款</view>
                        <el-link href="https://element-plus.org" target="_blank" style="color:white;">隐私政策</el-link>
                        <el-link href="https://element-plus.org" target="_blank" style="color:white;">服务条款</el-link>
                        <el-link href="https://element-plus.org" target="_blank" style="color:white;">支持政策</el-link>
                    </div>
                    <div style="display: flex; flex-direction: column; text-align: left; color: white;">
                        <view style="font-size: 19px; color: white; padding-bottom: 30px;">Links</view>
                        <el-link href="https://element-plus.org" target="_blank" style="color:white;">澳大利亚ABN</el-link>
                        <el-link href="https://element-plus.org" target="_blank" style="color:white;">澳大利亚GST/TAX</el-link>
                        <el-link href="https://element-plus.org" target="_blank" style="color:white;">澳大利亚工商</el-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '../utils/Utils'
import { ElMessage } from 'element-plus'
import CryptoJS from 'crypto-js'
// import i18n from '@/i18n/i18n';
import { useI18n } from 'vue-i18n'
// const { locale } = useI18n();
export default {
    data() {
        return {
            company: {
                companyEmail: ''
            },
            isLogin: true,
            step: 1,
            isLoading: false,
            f: '',
            s: '',
            t: '',
            fs: '',
            lan: null,
            form: {}
        }
    },
    methods: {
        registerHandler: function () {
            this.isLogin = false
        },
        toLoginHandler: function () {
            this.isLogin = true
        },
        onNextToTwo: function () {

            this.isLoading = true;
            // 对两个密码进行校验
            if (this.company.companyPwd != this.company.conPwd) {
                ElMessage({ showClose: true, message: '两次输入的密码不一致.', type: 'error', })
                return;
            }

            // 发送电子邮件
            this.axios.post(utils.baseUrl + 'reservationCompany/sendEmail', this.company).then((res) => {
                if (res.data.code === 500) {
                    ElMessage({ showClose: true, message: '当前邮箱已经被使用.', type: 'error', })
                } else {
                    ElMessage({ showClose: true, message: '验证邮件已发送，请登录邮箱查看.', type: 'success', })
                    this.step = 2;
                }
                this.isLoading = false;
            }).catch(() => {
                this.isLoading = false;
            })
        },
        onNextToThree: function () {
            // 注册
            this.isLoading = true;
            let that = this;
            this.company.companyRandom = this.f + this.s + this.t + this.fs;
            this.company.companyArea = '';
            this.company.companyLogo = utils.baseUrl + 'logo.png';
            this.axios.post(utils.baseUrl + 'reservationCompany/register', this.company).then((res) => {

                if (res.data.code === 200) {
                    this.step = 3;
                    that.company = res.data.data;
                    that.isLoading = false;
                } else {
                    ElMessage({ showClose: true, message: res.data.message, type: 'error', })
                }
            })
        },
        beginHandler: function () {
            this.$router.push('/home/' + this.company.companyId + '/' + this.company.companyIdnum)
        },
        change1Handler: function () {
            console.log('--------------')
            document.getElementById('sec').focus();
        },
        change2Handler: function () {
            document.getElementById('thr').focus();
        },
        change3Handler: function () {
            document.getElementById('for').focus();
        },
        change4Handler: function () {
            // document.getElementById('fir').focus();
        },
        onSubmit: function () {
            this.isLoading = true;
            let that = this;
            this.company.md5 = CryptoJS.MD5(JSON.stringify(this.company)).toString()
            this.axios.post(utils.baseUrl + 'reservationCompany/login', this.company).then((res) => {

                that.isLoading = false;
                if (res.data.code === 500) {
                    ElMessage({ showClose: true, message: res.data.message, type: 'error', })
                } else {
                    let companyId = res.data.data.companyId;
                    let num = res.data.data.companyIdnum;
                    this.$router.push({ name: 'home', params: { 'companyId': companyId, 'num': num } })
                }
            }).catch((res) => {
                ElMessage({ showClose: true, message: '登录失败' + res, type: 'error', })
            })
        },
        openPage: function (url) {
            window.open(utils.staUrl + url, "_blank");
        }
    },
    created() {
        this.lan = useI18n()
    }
}
</script>
<style scoped>
body {
    margin: 0px;
    padding-left: 200px;
    padding-right: 200px;
    background-color: #3270E0;
}

.div_class {
    width: 35px;
    height: 35px;
    /* border: 1px solid #A8A8A8; */
    text-align: center;
    font-size: 20px;
}

.el-menu-demo {
    background-color: #3270E0;
    color: white;
    font-size: 15px;
    width: 500px;
    border: none;
}

.el-menu--horizontal {
    --el-menu-horizontal-height: 50px;
    color: white;
}
.custom-label-color .el-form-item__label{
    color: #FFFFFF !important;
}
</style>